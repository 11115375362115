











































import iconMobileJson from '@/components/Header/Shortcuts/Icons-mobile.json';
import HeaderLogo from '@/components/Header/HeaderLogo.vue';
import HeaderShortcuts from '@/components/Header/Shortcuts/Shortcut.vue';
import { SfButton } from '@storefront-ui/vue';
import { useCategoryStore } from '@/modules/catalog/category/stores/category';
import { useUiState } from '@/composables';
import MobileCategorySidebar from '@/modules/catalog/category/components/sidebar/MobileCategorySidebar/MobileCategorySidebar.vue';
import { ref } from '@nuxtjs/composition-api';
import Search from './SearchBar/Search.vue';
const __sfc_main = {};
__sfc_main.setup = (__props, __ctx) => {
  const isSearchOpen = ref<boolean>(false);
  const {
    toggleMobileMenu,
    isMobileMenuOpen
  } = useUiState();
  const handleMenuClick = () => {
    loadMenu();
  };
  const openSearch = () => {
    isSearchOpen.value = true;
  };
  const loadMenu = async () => {
    const categories = useCategoryStore();
    if (categories.categories === null) {
      await categories.load();
    }
    toggleMobileMenu();
  };
  const closeSearch = () => {
    isSearchOpen.value = false;
  };
  return {
    iconMobileJson,
    isSearchOpen,
    isMobileMenuOpen,
    handleMenuClick,
    openSearch,
    closeSearch
  };
};
__sfc_main.components = Object.assign({
  SfButton,
  HeaderLogo,
  HeaderShortcuts,
  MobileCategorySidebar,
  Search
}, __sfc_main.components);
export default __sfc_main;
