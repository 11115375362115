import { computed, Ref } from '@nuxtjs/composition-api';
import { getGallery as getProductGallery } from '~/modules/catalog/product/getters/productGetters';
import { useImage } from '~/composables';
import type { Product } from '~/modules/catalog/product/types';
import type { UseProductGalleryInterface } from '~/modules/catalog/product/composables/useProductGallery/useProductGallery';

/**
 * The `useProductGallery()` composable allows building product's gallery data structure.
 *
 * See the {@link UseProductGalleryInterface} page for more information.
 */
 export function useProductGallery(product: Ref<Product>, imgPlaceholder = 'blur'): UseProductGalleryInterface {
  const { getMagentoImage, imageSizes } = useImage();
  const gallery =  getProductGallery(product.value)
  const productGallery = computed(() => {
    const indexedGallery = gallery.map((img, index) => ({
      ...img,
      originalIndex: index,
    }));
    let processedGallery = indexedGallery.map((img) => ({
      mobile: { url: getMagentoImage(img.small) },
      desktop: { url: getMagentoImage(img.normal) },
      big: { url: getMagentoImage(img.big) },
      alt: img.label ? img.label : product.value.name,
      placeholder: imgPlaceholder,
      originalIndex: img.originalIndex,
    }));
    return processedGallery;
  });

  const orderMediaGalleryByPosition = (product) => {
    return product?.media_gallery?.sort((a, b) => parseFloat(a.position) - parseFloat(b.position));
  }

  return {
    orderMediaGalleryByPosition,
    productGallery,
    imageSizes,
  };
}

export default useProductGallery;
export * from './useProductGallery';
