
















































import { defineComponent, PropType } from '@nuxtjs/composition-api';
import { SfPrice } from '@storefront-ui/vue';
import { useProductsWithCommonProductCardProps } from '~/modules/catalog/category/components/views/useProductsWithCommonCardProps';
import productGetters from '~/modules/catalog/product/getters/productGetters';

export default defineComponent({
  name: 'CategoryProductPrice',
  components: {
    SfPrice,
  },
  props: {
    product: {
      type: Object as PropType<
        | ReturnType<
            typeof useProductsWithCommonProductCardProps
          >['productsWithCommonProductCardProps']['value'][number]
        | null
      >,
      required: true,
    },
    font: {
      type: String,
      required: false,
    }
  },
  setup(props) {
    return {
      productGetters,
    };
  },
});
