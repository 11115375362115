import { readonly, ref } from '@nuxtjs/composition-api';
import type { ComposableFunctionArgs } from '~/composables/types';
import useApi from '~/composables/useApi';
import { SortingOptions } from '~/modules/catalog/category/composables/useFacet/SortingOptions';
import { PerPageOptions } from '~/modules/catalog/category/composables/useFacet/PerPageOptions';
import { createProductAttributeFilterInput } from '~/modules/catalog/category/composables/useFacet/input/createProductAttributeFilterInput';
import { createProductAttributeSortInput } from '~/modules/catalog/category/composables/useFacet/input/createProductAttributeSortInput';
import { Products } from '~/modules/GraphQL/types';
import GetFacetDataCustomQuery from './getFacetDataCustom.gql';
import getNumberOfProduct from './getNumberOfProduct.gql';
import type { UseFacetInterface, UseFacetErrors, UseFacetSearchResult, FacetSearchParams } from './useFacet';
import { ProductRepository } from '~/repository/products.repository';
import { RedisService } from '~/service/redis.service'

/**
 * The `useFacet()` composable allows searching for products using facets.
 *
 * What makes it powerful is the ability to accept multiple filters, allowing to
 * narrow down the results to a specific category, search term, etc.
 */
export function useFacet(): UseFacetInterface {
  const redisService = new RedisService();
  const { query } = useApi();
  const loading = ref(false);
  const result = ref<UseFacetSearchResult>({ data: null, input: null });
  const error = ref<UseFacetErrors>({
    search: null,
  });
  const defaultItemsPerPage = 24;
  const search = async (params?: ComposableFunctionArgs<FacetSearchParams>) => {
    result.value.input = params;
    try {
      loading.value = true;
      const isNotFilter = Object.keys(params.filters).length === 0;
      const pageSize = params.itemsPerPage ? params.itemsPerPage : defaultItemsPerPage;
      const sort = createProductAttributeSortInput(params.sort || 'position_ASC');
      const sortField = Object.keys(sort).toString();
      const sortValue = Object.values(sort).toString();
      if (isNotFilter && !params.sort.includes('price')) {
        const productRepository = new ProductRepository();
        let sortProduct;
        sortProduct = await productRepository.getAllProducts({
          categoryId: createProductAttributeFilterInput(params).category_id.in[0],
          currentPage: params.page,
          pageSize: pageSize,
          sortField: sortField,
          sortValue: sortValue,
        });
        const skus = sortProduct.items.map((item) => item.sku);
        const filterSku = {
          sku: {
            in: skus,
          },
        };
        const productSearchParams = {
          pageSize,
          search: params.term ? params.term : '',
          filter: skus.length > 0 ? filterSku : createProductAttributeFilterInput(params),
          sort: createProductAttributeSortInput(params.sort || ''),
          currentPage: 1,
        };
        let products

        const cacheKeyGetFacetDataCustomQuery = `category_products_graphql_${createProductAttributeFilterInput(params).category_id.in[0]}_${params.page}_${productSearchParams.pageSize}_${Object.keys(productSearchParams.sort)[0] === '' ? 'position' : Object.keys(productSearchParams.sort)}_${Object.values(productSearchParams.sort)[0] === undefined ? 'asc' : Object.values(productSearchParams.sort)}`;

        products = await getRedisGraphql(cacheKeyGetFacetDataCustomQuery,productSearchParams);

        const sortedProducts = [];

        for (let i = 0; i < skus.length; i++) {
          const sku = skus[i];
          const product = products.items.find((p) => p.sku === sku);
          if (product) {
            sortedProducts.push(product);
          }
        }
        result.value.data = {
          items: sortedProducts ?? [],
          total: sortProduct.total_count,
          availableSortingOptions: SortingOptions,
          perPageOptions: PerPageOptions,
          itemsPerPage: 24,
        };
      } else {
        let products;
        const productSearchParams = {
          pageSize,
          search: params.term ? params.term : '',
          filter: createProductAttributeFilterInput(params),
          sort: createProductAttributeSortInput(params.sort || ''),
          currentPage: params.page,
        };

        const filterKeys = productSearchParams.filter;
        const hasOnlyCategoryId = Object.keys(filterKeys).length === 1 && Object.keys(filterKeys)[0] === 'category_id';
        const cacheKeyGetFacetDataCustomQuery = `category_products_graphql_${createProductAttributeFilterInput(params).category_id.in[0]}_${productSearchParams.currentPage}_${productSearchParams.pageSize}_${Object.keys(productSearchParams.sort)[0] === '' ? 'position' : Object.keys(productSearchParams.sort)}_${Object.values(productSearchParams.sort)[0] === undefined ? 'asc' : Object.values(productSearchParams.sort)}`;

        if (hasOnlyCategoryId) {
          products = await getRedisGraphql(cacheKeyGetFacetDataCustomQuery,productSearchParams) 
        } else {
          const { products: productGraphql } = await query<{ products: Products }>(GetFacetDataCustomQuery, productSearchParams);
          products = productGraphql;
        }
        
        result.value.data = {
          items: products.items ?? [],
          total: products?.total_count,
          availableSortingOptions: SortingOptions,
          perPageOptions: PerPageOptions,
          itemsPerPage: 24,
        };
      }
      error.value.search = null;
    } catch (err) {
      error.value.search = err;
    } finally {
      loading.value = false;
    }
  };

  const getRedisGraphql = async (cacheKey, searchParam) => {
    let products
    try {
        return await redisService.GetRedis(cacheKey);
    } catch (cacheError) {
      console.error('Erreur lors de la récupération des données du client depuis le cache:', cacheError);
      const { products: productGraphql } = await query<{ products: Products }>(GetFacetDataCustomQuery, searchParam);
      products = productGraphql;
      try {
        await redisService.SetRedis(cacheKey, JSON.stringify(products));
      } catch (apiError) { 
        console.error('Erreur lors de l\'ajout du cache:', error);
      }
    }
    return products;
  }

  return {
    search,
    result,
    error: readonly(error),
    loading: readonly(loading),
  };
}

export * from './useFacet';
export default useFacet;
