import { render, staticRenderFns } from "./ShortcutItem.vue?vue&type=template&id=0bd77a09&scoped=true&"
import script from "./ShortcutItem.vue?vue&type=script&lang=ts&"
export * from "./ShortcutItem.vue?vue&type=script&lang=ts&"
import style0 from "./ShortcutItem.vue?vue&type=style&index=0&id=0bd77a09&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0bd77a09",
  null
  
)

export default component.exports